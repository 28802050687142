@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Proxima Nova";
  src: "url(/src/assets/Fonts/ProximaNova-Regular.otf)";
  font-weight: 400;
}
@font-face {
  font-family: "Proxima Nova";
  src: "url(/src/assets/Fonts/ProximaNova-Light.otf)";
  font-weight: 300;
}
@font-face {
  font-family: "Proxima Nova";
  src: "url(/src/assets/Fonts/ProximaNova-Bold.otf)";
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: "url(/src/assets/Fonts/ProximaNova-Semibold.otf)";
  font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-size: 16px;
  font-family: "Proxima Nova", sans-serif;
}
.btn {
  border-radius: 15px;
  border: 1px solid;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn:hover {
  background-color: #000000;
  border-color: #000000;
  opacity: 0.8;
  color: #fff !important;
}
.btn > i {
  font-size: 15px;
}
h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}
.fs-10 {
  font-size: 10px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.common_box {
  box-shadow: 0px 0px 15px #e4e4e4b8;
  border-radius: 20px;
  width: 100%;
  margin: auto;
}
.common_box_body {
  padding: 45px 35px 35px;
}
.custom_form .form-group {
  margin-bottom: 20px;
}
.cf_label {
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin-bottom: 4px;
}
.cf_input {
  font-size: 14px;
  line-height: 21px;
  color: #000;
  min-height: 50px;
  border-radius: 15px;
  padding: 12px 20px;
  border: 1px solid #c1c1c1;
}
.border-3 {
  background: #e9e9e9;
  border-radius: 10px;
  padding: 13px 16px;
}
.forgot-pass {
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0px;
  color: red;
  text-decoration: none;
}
.pos-r {
  position: relative;
}
.pos-r img {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  filter: brightness(0);
}
/* modal css */
.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}
.modal-custom-sm {
  max-width: 318px;
}
.modal-content,
.modal-body {
  background-color: transparent;
  box-shadow: none;
  border: 0;
}
.modal-body {
  position: relative;
}
.modal-body .close {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 1;
  border: 1px solid #000000;
  position: absolute;
  z-index: 1;
  right: -14px;
  top: -11px;
  font-weight: 400;
}
/* Dashboard page css */
.columnWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.left-sidebar {
  background-color: #24272c;
  max-width: 200px;
  width: 100%;
  position: fixed;
  height: 100vh;
}
.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 83px;
  color: #000;
}
.sideNavBar {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.navbar-nav .nav-link,
.navbar-nav .dropdown-menu a {
  padding: 15px 20px !important;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.56px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}
.navbar-nav .nav-link:hover,
.navbar-nav .dropdown-menu a:hover,
.nav-link.active,
.nav-link:hover {
  color: #fff !important;
  background: linear-gradient(180deg, #e4262f 0%, #e4262f 100%);
}
.offcanvas-body .navbar-nav {
  display: flex;
  flex-direction: column;
}
div#mainPart {
  width: calc(100% - 200px);
  width: 100%;
  float: right;
  padding-left: 200px;
  background-color: #fafafa;
}
.left-sidebar .offcanvas {
  width: 100% !important;
}
.main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px;
  box-shadow: 0px 2px 8px #00a6e614;
  background-color: #fff;
}
.mch-right-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-btn {
  border: 0;
  background-color: transparent;
  padding: 0;
  font-size: 13px;
  line-height: 15px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-btn:focus,
.user-btn:active,
.user-btn:hover,
.user-btn:focus-visible {
  background-color: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
}
.user-btn img {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
.user-btn::after {
  content: none;
}
.user-menu {
  margin-left: 35px;
}
.user-dropmenu.show {
  padding: 0;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 30%);
  border: 0;
  border-radius: 9px;
  overflow: hidden;
}
.user-dropmenu a.dropdown-item {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.56px;
  font-weight: 500;
  padding: 8px 19px;
}
.main-content-body {
  padding: 30px 24px 38px;
}
.dash-common {
  padding: 30px 0;
}
.paginate_button.page-item {
  margin: 0 5px;
}
.paginate_button.page-item a {
  border-radius: 50%;
  color: #000;
  padding: 0 !important;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.paginate_button.page-item.previous a,
.paginate_button.page-item.next a {
  border-radius: 50%;
}
.source-table{
  width: 80%;
}
.common-table-wrapper table tbody tr:nth-child(odd) {
  background-color: #f8f9ff;
}
.common-table-wrapper table thead {
  background-color: #000000;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.common-table-wrapper table thead th {
  font-size: 15px;
  line-height: 24px;
  font-weight: 300;
  padding: 16px 16px 12px 16px;
  text-align: center;
}
.common-table-wrapper table tbody td {
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  padding: 16px 16px 12px 16px;
  text-align: center;
}
.common-table-wrapper table thead th:first-child {
  padding-left: 28px;
  border-top-left-radius: 10px;
  text-align: left;
}
.common-table-wrapper table thead th:last-child {
  border-top-right-radius: 10px;
}
.common-table-wrapper table tbody td:first-child {
  padding-left: 28px;
  text-align: left;
}
.common-table-wrapper table th,
.common-table-wrapper table td {
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
}
.pos-abs {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.page-item.active .page-link {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}
.paginate_button.page-item a svg {
  width: 20px;
  height: 20px;
}
.modalMainTitle {
  text-align: center;
  font: normal normal 500 19px/29px poppins;
  color: #000000;
}
.form-group.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.btn-form {
  width: 134px;
  height: 40px;
  border: 0.30000001192092896px solid;
  border-radius: 10px;
  font: normal normal 500 14px/21px poppins;
  padding: 9px 9px;
}
.btn-wrapper {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}
.btn-form.btn-cancel {
  border-color: rgb(15 15 51 / 35%);
  color: #000;
}
.btn-form.btn-add {
  background-image: linear-gradient(180deg, #f11829 0%, #fe0249 100%);
  border-color: #f11829;
  color: #fff;
}
.form-group h5 {
  font: normal normal 600 16px/25px poppins;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
table {
  box-shadow: 0px 0px 3px #c7c7c7b7;
  border-radius: 10px;
  width: 100%;
}
.nav-pills-tabs .nav-link {
  border-radius: 22px;
  border-radius: 22px;
  background-color: #000 !important;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
.nav-pills-tabs .nav-item:not(:last-child) {
  margin-right: 11px;
}
.box-border-3 {
  background: #ececec;
  border-radius: 21px;
  padding: 31px;
}
.edit-icon {
  width: 28px;
  height: 28px;
  background-color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  color: #fff;
}
.edit-icon i {
  font-size: 15px;
  color: #fff;
}
.textarea-round {
  border: 0;
  border-radius: 14px;
  overflow: hidden;
  color: #000 !important;
}
.faq-list {
  display: flex;
}
.faq-list p {
  flex: 0 1 94%;
  padding-right: 10px;
}
.rounded-50 {
  border-radius: 50px;
  color: #000;
  border: 0;
}
.login-wrapper {
  max-width: 350px;
  width: 100%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.full-wrapper-spin {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  h2 {
    font-size: 20px;
    line-height: 120%;
  }
  .nav-pills-tabs .nav-link {
    margin: 5px 0;
  }
}
@media (max-width: 767px) {
  .left-sidebar {
    max-width: 100%;
    position: unset;
    height: auto;
  }
  div#mainPart {
    padding-left: 0;
  }
  .mobile-menu {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }
  .mobile-menu .navbar-brand {
    justify-content: flex-start;
    padding: 10px 24px;
  }
  .main-content-header {
    padding: 20px 24px;
  }
  .offcanvas-header {
    text-align: center;
    background-color: #000;
    justify-content: flex-end;
  }
  .offcanvas-body {
    padding: 0;
    background-color: #000;
  }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .btn-close {
    background-color: #fff !important;
    width: 0.8em;
    height: 0.8em;
    font-size: 12px;
    opacity: 1;
  }
  .offcanvas-body .navbar-nav {
    height: 350px;
    overflow-y: scroll;
  }
}
.icons {
  font-size: 25px;
  margin: 0 40px;
}
.total-text {
  letter-spacing: 1px;
}
.total-middle-text {
  font-size: 15px;
}
.icon {
  color: #ffa800;
}
.offcanvas-body .navbar-nav {
  height: 85vh;
  overflow-y: scroll;
}
.offcanvas-body .navbar-nav::-webkit-scrollbar {
  width: 3px;
}
.offcanvas-body .navbar-nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000;
}
.offcanvas-body .navbar-nav::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid #f1f1f1;
  border-radius: 10px;
}
.carousel.slide .carousel-inner {
  height: 100%;
}
.slide .carousel-indicators [data-bs-target] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.slide .carousel-indicators {
  align-items: center;
}
.slide .carousel-indicators [data-bs-target].active {
  width: 8px;
  height: 8px;
  background-color: #000;
}
.dropdown-toggle.btn {
  min-height: auto;
}
.dropdown-toggle.btn::before,
.dropdown-toggle.btn::after {
  content: none;
}
